//封装axios请求

import axios from 'axios'

const instance = axios.create({
    baseURL: '//www.camtue.com/api', // 基础URL
    timeout: 5000, // 超时时间

})

// 请求拦截器  
instance.interceptors.request.use(config => {
    // 在发送请求之前做些什么  
    // ...  
    // console.log('Sending request:', config);  // 添加这一行以在控制台中打印出请求信息  
    return config;
}, error => {
    // console.error('Request error:', error);  // 添加这一行以在控制台中打印出请求错误  
    // 对请求错误做些什么  
    // ...  
    return Promise.reject(error);
})

// 响应拦截器  
instance.interceptors.response.use(response => {
    // 对响应数据做点什么  
    // ...  
    // console.log('Response data:', response);  // 添加这一行以在控制台中打印出响应数据  
    return response;
}, error => {
    // console.error('Response error:', error);  // 添加这一行以在控制台中打印出响应错误  
    // 对响应错误做点什么  
    // ...  
    if (error.response.status === 401) {
        // 例如,如果响应状态码为401,可以跳转到登录页面  
        window.location.href = '/login';
    } else if (error.response.status >= 500) {
        // 如果服务器返回500错误，记录错误信息  
        // console.error('Server error:', error);  // 添加这一行以在控制台中打印出服务器错误信息  
    }
    return Promise.reject(error);
});

export default instance